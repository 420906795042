// This file is automatically compiled by Webpack.
// You're encouraged to place your actual application logic in a
// relevant directory structure within this directory and
// only use this file to reference that code so it'll be compiled.

// You can directly reference code in the '_components' directory to
// include it in your bundle. Webpack will automatically locate it.

import "index.css"
import "easter_egg"
import "swup_config"
import "nav_hooks"

import AOS from 'aos';
import 'aos/dist/aos.css';

document.addEventListener("DOMContentLoaded", () => {
  document.body.classList.remove("no-js")

  AOS.init({
    once: true,
    duration: 500
  });
})