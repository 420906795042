export class EasterEgg {  
  constructor() {
    this.track = ""
    this.targetTerm = "god"
    document.addEventListener('DOMContentLoaded', this.configureListener.bind(this))
  }
  
  configureListener() {  
    this.listener = new KeyListener(this.keyPressed.bind(this))
  }
  
  keyPressed(key) {
    this.track = this.track + key
    
    if (this.track == this.targetTerm) {
      window.location = "https://www.youtube.com/watch?v=GQsmGAURyC4"
    } else if (this.track != this.targetTerm.substring(0, this.track.length)) {
      this.track = ""
    }
    
    console.log(this.track)
  }  
}

const KeyNames = {
  71: 'g',
  79: 'o',
  68: 'd'
}

class KeyListener {
  constructor(handler) {
    this.handler = handler
    
    document.addEventListener("keydown", this.handleKeyPress.bind(this))
  }
    
  handleKeyPress(event) {
    let key = KeyNames[event.keyCode]
    if (key) {
      this.handler(key)
    }
  }
}