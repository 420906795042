import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';

document.addEventListener('DOMContentLoaded', () => {
  if(!window.matchMedia('(prefers-reduced-motion)').matches && document.getElementById("swup")) {
    const swup = new Swup({
      linkSelector: "a[data-swup]",
      plugins: [new SwupPreloadPlugin()]
    })
  }
})