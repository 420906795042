document.addEventListener("scroll", () => {
  let hooks = document.querySelectorAll("[data-activate-nav-item]")
  if (hooks.length == 0) { return }

  document.querySelectorAll("nav a").forEach (navItem => {
    navItem.setAttribute("am-state", "inactive")
  })

  let hook = getActiveHook(hooks)
  if (hook == undefined) { return }

  let target = `${hook.dataset.activateNavItem}`
  let navItem = document.querySelector(`nav a[href="/#${target}"]`)

  navItem.setAttribute("am-state", "active")
  forceRepaint(navItem)
})

function getActiveHook(hooks) {
  let activeHook = undefined

  hooks.forEach(hook => {
    let headerHeight = document.querySelector("header").clientHeight
    let isTopWithinThreshold = window.scrollY >= (hook.offsetTop - headerHeight - 60)
    let isBottomWithinThreshold = window.scrollY < (hook.offsetTop + hook.clientHeight/1.5)

    if (isTopWithinThreshold && isBottomWithinThreshold) {
      activeHook = hook
    }
  })

  return activeHook
}

function forceRepaint(element) {
  element.style.display = "none"
  element.offsetHeight
  element.style.removeProperty("display")
}